import { featureFlags } from './featureFlag';

export * from './auth';
export * from './featureFlag';

export const appConfig = {
    publicUrl: process.env.REACT_APP_PUBLIC_URL || '',
    gateway: {
        serviceUrl: process.env.REACT_APP_SERVICE_URL || '',
    },
    googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
    featureFlags: featureFlags,
    peopleHrUrl: process.env.REACT_APP_PEOPLE_HR_URL || '',
    apiKey: process.env.REACT_APP_API_KEY || '',
    vapidKey: process.env.REACT_APP_VAPID_KEY || '',
    powerBIReportId: process.env.REACT_APP_POWER_BI_REPORT_ID || '',
    disabledFutureTimesheet: process.env.REACT_APP_DISABLED_FUTURE_TIMESHEET !== 'false',
};
console.log(`appConfig`, appConfig);
